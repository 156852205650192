import { Box, Skeleton, Stack, Typography } from "@mui/material"
import React from "react"
import useCustomer from "../../../hooks/useCustomer"

const Loading = () => (
  <Box
    p={2}
    sx={{
      width: "100%",
      height: "100%",
      boxSizing: "border-box",
      borderRadius: "15px",
      boxShadow: "0px 10px 60px rgba(226, 236, 249, 0.5)",
      overflow: "hidden",
    }}
  >
    <Skeleton>
      <Typography variant="h5">{""}</Typography>
    </Skeleton>
    <Skeleton>
      <Typography variant="h6">{""}</Typography>
    </Skeleton>
    <Skeleton>
      <Typography variant="span">{"LTV: 10 000"}</Typography>
    </Skeleton>
    <Skeleton>
      <Typography variant="span">{"Последний филиал: Фучика"}</Typography>
    </Skeleton>
  </Box>
)

export default function Client({ phone }) {
  const { customer } = useCustomer(phone)
  if (!customer) return <Loading />

  return (
    <Box
      p={2}
      sx={{
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        borderRadius: "15px",
        boxShadow: "0px 10px 60px rgba(226, 236, 249, 0.5)",
        overflow: "hidden",
        background: "#fff",
      }}
    >
      <Stack direction={"column"}>
        <Typography variant="h5">{`ФИО: ${customer?.name || "-"}`}</Typography>
        <Typography variant="h6">{`Телефон: ${phone}`}</Typography>
        <Typography variant="span">{`Email: ${
          customer?.email || "-"
        }`}</Typography>
        <Typography variant="span">{`Дата рождения: ${
          customer?.date_of_birth || "-"
        }`}</Typography>
      </Stack>
    </Box>
  )
}
