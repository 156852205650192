import React, { useEffect, useState } from "react"
import {
  Button,
  TextField,
  Grid,
  Typography,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Box,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
  useTheme,
  useMediaQuery,
  ButtonGroup,
} from "@mui/material"
import { Controller, useForm } from "react-hook-form"
import closeIcon from "../../assets/images/close.png"
import { useAuthContext } from "../../hocs/AuthProvider"
import { useSnackbarContext } from "../../hocs/SnackbarProvider"
import { LegendToggle, RefreshOutlined } from "@mui/icons-material"
import Modal from "../Modal"
import { Link } from "react-router-dom"

const saveCall = async (data, call, headers, showSnackbar) => {
  const readyData = {
    result: {},
  }
  if (data?.result) readyData.result.result_type = { id: data.result }
  if (data?.master && data.master !== -1) readyData.master_id = data.master
  if (data?.department && data.department)
    readyData.department = data.department
  if (data?.comment && data.comment) readyData.result.comment = data.comment
  if (data?.price && data.price) readyData.result.price = data.price
  const res = await fetch(process.env.REACT_APP_API_URL + `calls/${call.id}/`, {
    headers: headers,
    method: "PUT",
    body: JSON.stringify(readyData),
  }).catch((e) => showSnackbar("Не удалось сохранить звонок"))
  if (res?.ok && res.ok) {
    if (res.status === 403) {
      showSnackbar("Недостаточно прав доступа")
      return false
    }
    const resData = await res.json()
    return resData
  }
  showSnackbar("Не удалось сохранить звонок")
  return false
}

const getTextOfCall = async (call, headers, showSnackbar) => {
  const res = await fetch(
    process.env.REACT_APP_API_URL + `calls/${call.id}/text/`,
    {
      headers: headers,
      method: "GET",
    }
  ).catch((e) => showSnackbar("Не удалось получить текст звонка"))
  if (res?.ok && res.ok) {
    if (res.status === 403) {
      showSnackbar("Недостаточно прав доступа")
      return false
    }
    const resData = await res.json()
    return resData
  }
  showSnackbar("Не удалось получить текст звонка")
  return false
}

const AudioPlayer = ({ call, refreshCall }) => {
  const allowedSpeeds = [1.0, 1.5, 2.0]
  const [speed, setSpeed] = useState(1.5)
  let recordSrc = undefined

  if (call.record) {
    recordSrc = call.record ? call.record : ""
  }
  let audioPlayer = (
    <audio controls src={recordSrc} id={`audio-${call.id}`}>
      <a href={call.record}>Download audio</a>
    </audio>
  )

  const changeSpeed = () => {
    let index = allowedSpeeds.indexOf(speed)
    index++
    if (index >= allowedSpeeds.length) index = 0
    const newSpeed = allowedSpeeds[index]
    let audio = document.getElementById(`audio-${call.id}`)
    audio.playbackRate = newSpeed
    setSpeed(newSpeed)
  }

  useEffect(() => {
    let audio = document.getElementById(`audio-${call.id}`)
    audio.playbackRate = speed
  }, [])

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexFlow: "row nowrap",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "10px",
      }}
    >
      {audioPlayer}
      <Button
        onClick={changeSpeed}
        color="primary"
        variant="contained"
        size={"small"}
      >
        <Typography>{`x ${speed.toString()}`}</Typography>
      </Button>
      <IconButton onClick={() => refreshCall(call.id)}>
        <RefreshOutlined />
      </IconButton>
    </Box>
  )
}

const ModalHeader = ({ onClose, call }) => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography variant="h6">{`Звонок: ${call.phone}`}</Typography>
      <IconButton
        sx={{
          width: "40px",
          padding: "7px",
          marginRight: "-7px",
          height: "40px",
        }}
        onClick={onClose}
      >
        <img
          style={{ width: "100%", height: "100%" }}
          src={closeIcon}
          alt="close"
        />
      </IconButton>
    </Box>
  )
}

const CallSelects = ({ call, fields, register, size, control, errors }) => {
  const selects = fields.selects.filter((s) => {
    if (["master"].includes(s.id)) return s
  })

  return (
    <Grid container spacing={2}>
      {selects.map((s) => (
        <Grid item md={6} lg={6} key={s.id}>
          <FormControl
            sx={{ width: "100%", boxSizing: "border-box" }}
            size={size}
          >
            <InputLabel
              variant={"outlined"}
              id={`${s.id}-modal-select-label-id`}
            >
              {s.title}
            </InputLabel>
            <Controller
              defaultValue={call?.master ? call.master.id : -1}
              render={({ field }) => (
                <Select
                  label={s.title}
                  sx={{ width: "100%", boxSizing: "border-box" }}
                  variant="outlined"
                  size={size}
                  labelId={`${s.id}-modal-select-label-id`}
                  {...field}
                >
                  {s.options.map((op) => (
                    <MenuItem key={op.id} value={op.id}>
                      {op?.name ? op.name : op?.title ? op.title : "-"}
                    </MenuItem>
                  ))}
                </Select>
              )}
              name={s.id}
              control={control}
            />
          </FormControl>
        </Grid>
      ))}
      <Grid item xs={12} md={6} lg={6} sx={{ width: "100%" }}>
        <TextField
          size={size}
          sx={{ width: "100%" }}
          label="Цена"
          {...register("price", {
            value: call.result?.price ? call.result.price : undefined,
            pattern: {
              value: /^[+-]?\d+(\.\d+)?$/,
              message: "Это не число",
            },
          })}
          error={errors?.price}
          helperText={errors?.price?.message}
        />
      </Grid>
    </Grid>
  )
}

const CallInfo = ({ call, refreshCall, getText }) => {
  return (
    <Grid
      container
      spacing={2}
      p={1}
      sx={{
        boxSizing: "border-box",
        maxWidth: "100%",
        margin: 0,
        backgroundColor: "#ddd",
        borderRadius: "15px",
        boxSizing: "border-box",
      }}
    >
      <Grid item xs={12} md={6} lg={6}>
        Дата:{" "}
        <Typography variant="span" sx={{ fontWeight: 600 }}>
          {call.date_time_string ? call.date_time_string : "-"}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        Подразделение:{" "}
        <Typography variant="span" sx={{ fontWeight: 600 }}>
          {call.department?.title ? call.department.title : "-"}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        Клиент:{" "}
        <Typography variant="span" sx={{ fontWeight: 600 }}>
          <Link to={`/u/client/${call.phone}`}>{call.phone}</Link>
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        Оператор:{" "}
        <Typography variant="span" sx={{ fontWeight: 600 }}>
          {call.manager ? call.manager.username : "-"}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <Button onClick={getText}>Получить текст</Button>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        Результат (ИИ):{" "}
        <Typography variant="span" sx={{ fontWeight: 600 }}>
          {call?.ai_result || "-"}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        Комментарий (ИИ):{" "}
        <Typography variant="span" sx={{ fontWeight: 600 }}>
          {call?.ai_comment || "-"}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        display={"flex"}
        justifyContent={"center"}
      >
        <AudioPlayer call={call} refreshCall={refreshCall} />
      </Grid>
    </Grid>
  )
}

const CallStatus = ({ register, call, fields, control, errors, size }) => {
  const [withOptions, setWithOptions] = useState(false)
  const onChangeWithOptions = (e, ch) => {
    if (e.target.checked) {
      // Добавляем select
      setWithOptions(ch)
    }
  }

  return (
    <Box>
      <Controller
        control={control}
        name="result"
        rules={{ required: "Обязательно поле" }}
        defaultValue={
          call.result?.result_type ? call.result.result_type.id : undefined
        }
        render={({ field }) => (
          <RadioGroup {...field} sx={{ width: "100%" }}>
            <Grid container spacing={1} sx={{ width: "100%", margin: 0 }}>
              {fields.checkboxs.map((ch) => (
                <Grid item xs={6} md={4} key={ch.id}>
                  <FormControlLabel
                    value={ch.id}
                    label={ch.title}
                    control={
                      <Radio
                        size={size}
                        onChange={
                          ch?.options && ch.options.length
                            ? (e) => onChangeWithOptions(e, ch)
                            : (e) => setWithOptions(false)
                        }
                      />
                    }
                  />
                </Grid>
              ))}
            </Grid>
            {errors?.result && (
              <Typography variant="span" color={"secondary"}>
                {errors.result.message}
              </Typography>
            )}
          </RadioGroup>
        )}
      />
      {withOptions && (
        <FormControl
          sx={{ width: "100%", boxSizing: "border-box" }}
          size={size}
        >
          <InputLabel
            variant={"outlined"}
            id={`no-service-more-modal-select-label-id`}
          >
            {withOptions.title}
          </InputLabel>
          <Controller
            // defaultValue={call?.master ? call.master.id : -1}
            render={({ field }) => (
              <Select
                label={withOptions.title}
                sx={{ width: "100%", boxSizing: "border-box" }}
                variant="outlined"
                size={size}
                labelId={`no-service-more-modal-select-label-id`}
                {...field}
              >
                <MenuItem>Не выбрано</MenuItem>
                {withOptions.options.map((op) => (
                  <MenuItem key={op.id} value={op.id}>
                    {op.title}
                  </MenuItem>
                ))}
                {/* {s.options.map(op => <MenuItem key={op.id} value={op.id}>{op?.name ? op.name : (op?.title) ? op.title : '-'}</MenuItem>)} */}
              </Select>
            )}
            name={"noServiceSelect"}
            control={control}
          />
        </FormControl>
      )}
    </Box>
  )
}

const Call = ({
  call,
  calls,
  setCalls,
  onClose,
  fields,
  register,
  handleSubmit,
  errors,
  control,
  size,
}) => {
  const { getAuthHeader } = useAuthContext()
  const { showSnackbar } = useSnackbarContext()

  const getText = () => {
    getAuthHeader({ "Content-type": "Application/json" }).then((h) => {
      getTextOfCall(call, h, showSnackbar).then((r) => {
        alert(r?.text_from_record)
      })
    })
  }

  const refreshCall = (callId) => {
    getAuthHeader({ "Content-type": "Application/json" }).then((h) => {
      fetch(process.env.REACT_APP_API_URL + `calls/${callId}/record_link/`, {
        headers: h,
        method: "POST",
      })
        .then((res) => {
          if (res.status === 403) {
            showSnackbar("Недостаточно прав доступа")
            return false
          }
          if (res?.ok || !res.ok) {
            showSnackbar("Не удалось обновить звонок")
          }
          console.log(res)
        })
        .catch((e) => showSnackbar("Не удалось обновить звонок"))
    })
  }

  const onSubmit = (data, send = false) => {
    getAuthHeader({ "Content-type": "Application/json" }).then((h) => {
      saveCall(data, call, h, showSnackbar).then((res) => {
        if (res) {
          onClose()
          setCalls({
            ...calls,
            results: calls.results.map((c) => (c.id === res.id ? res : c)),
          })
          if (send) {
            // Send notification to telegram
            fetch(
              process.env.REACT_APP_API_URL +
                `calls/${call.id}/send_notification/`,
              {
                method: "POST",
                headers: h,
              }
            )
              .catch((e) => showSnackbar("Не удалось отправить звонок"))
              .then((res) => {
                if (res.status === 403) {
                  showSnackbar("Недостаточно прав доступа")
                }
              })
          }
        }
      })
    })
  }

  return (
    // onSubmit={handleSubmit(onSubmit)}
    <form>
      <Box
        sx={{
          display: "flex",
          flexFlow: "column",
          gap: "30px",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <ModalHeader call={call} onClose={onClose} />
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            gap: "15px",
            boxSizing: "border-box",
            flexFlow: "column",
          }}
        >
          <CallInfo call={call} refreshCall={refreshCall} getText={getText} />
          <CallSelects
            control={control}
            errors={errors}
            call={call}
            size={size}
            fields={fields}
            register={register}
          />
          <CallStatus
            register={register}
            size={size}
            errors={errors}
            control={control}
            call={call}
            fields={fields}
          />
          <TextField
            size={size}
            multiline
            {...register("comment", {
              maxLength: {
                value: 1200,
                message: "Максимум 1200 символов",
              },
              value: call.result?.comment ? call.result.comment : undefined,
            })}
            label={"Комментарий"}
            error={errors?.comment}
            helperText={errors?.comment?.message}
          ></TextField>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Button
            variant="contained"
            color={"secondary"}
            onClick={onClose}
            size={size}
          >
            Отмена
          </Button>
          <ButtonGroup variant="contained" size={size}>
            <Button
              type="submit"
              color={"other"}
              sx={{ color: "#fff" }}
              value={"send"}
              onClick={handleSubmit((d) => onSubmit(d, true))}
            >
              Отправить
            </Button>
            <Button
              color={"primary"}
              type="submit"
              onClick={handleSubmit((d) => onSubmit(d, false))}
            >
              Сохранить
            </Button>
          </ButtonGroup>
        </Box>
      </Box>
    </form>
  )
}

export default function CallModal({ call, calls, setCalls, onClose, fields }) {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({ mode: "onBlur" })
  const theme = useTheme()
  const isSmScreen = useMediaQuery(theme.breakpoints.down("md"))
  const size = isSmScreen ? "small" : "medium"

  const onCloseFunc = () => {
    reset()
    onClose()
  }

  if (call)
    return (
      <Modal onClose={onClose} maxWidth={isSmScreen ? "100%" : "500px"}>
        <Call
          calls={calls}
          setCalls={setCalls}
          size={size}
          call={call}
          control={control}
          onClose={onCloseFunc}
          fields={fields}
          register={register}
          errors={errors}
          handleSubmit={handleSubmit}
        />
      </Modal>
    )

  return <></>
}
